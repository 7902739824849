<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="JWT"
      :properties="properties"
      step-label="jwt"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />
    <v-col cols="12" class="pb-2">
      <add-key-value
        :key="`${step.id}-payload`"
        type="payload"
        step-type="JWT"
        :can-edit="canEdit"
        :data="properties.payload"
        :single-step="step"
        @editor="$emit('openEditor', $event, 'payload')"
        @dataChanged="handleChange('payload', $event)"
      ></add-key-value>
    </v-col>
    <v-col cols="12" class="pt-1">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

export default {
  name: 'JWTStep',
  components: {
    CredentialsSelect,
    AddKeyValue
  },
  mixins: [StepMixin]
}
</script>
